<template>
  <div>
    <div class="d-flex justify-content-between">
      <el-button
          :size="size"
          @click="addItem"
          class="mr-2"
          type="primary"
      >
        <font-awesome-icon icon="plus" />
      </el-button>
      <el-button
          :loading="loading"
          :size="size"
          @click="saveTableData"
          type="success"
      >
        {{ $t('system.save') }}
      </el-button>
    </div>
    <div>
      <DataTable
          :headers="tableHeaders"
          :loading="loading"
          :model="data"
          :pagination="false"
          class="table"
      >
        <template v-slot:item.personAndDate="{row, idx}">
          <div class="d-flex mx-2 flex-column justify-content-around align-center table__cell">
            <div class="d-flex align-center ml-1 w-100">
              <font-awesome-icon icon="user" />
              <el-select
                  :class="{'validate-error': !isFieldValid(idx, 'technician_contractor_id')}"
                  :size="size"
                  class="ml-2 d-flex flex-grow-1"
                  filterable
                  v-model="row.technician_contractor_id"
              >
                <el-option
                    :key="person.id"
                    :label="person.name"
                    :style="{background: person.color}"
                    :value="person.id"
                    v-for="person in techniciansAndContractorsList"
                />
              </el-select>
            </div>
            <div class="d-flex align-center ml-1 w-100">
              <font-awesome-icon icon="calendar" />
              <el-date-picker
                  :class="{'validate-error': !isFieldValid(idx, 'labor_date')}"
                  :size="size"
                  class="ml-2 d-flex flex-grow-1"
                  format="yyyy-MM-dd"
                  v-model="row.labor_date"
                  value-format="yyyy-MM-dd"
              />
            </div>
          </div>
        </template>
        <template v-slot:item.driveAndLaborTime="{row, idx}">
          <div class="d-flex mx-2 flex-column justify-content-around table__cell">
            <div class="d-flex justify-content-around">
              <b>Drive</b>
              <div>
                <el-time-picker
                    :class="{'validate-error': !isFieldValid(idx, 'drive_from_hours')}"
                    :clearable="false"
                    :placeholder="$t('system.from')"
                    :size="size"
                    @change="updateTotalRowValue(row, 'drive')"
                    class="time-picker mx-1"
                    format="hh:mm A"
                    v-model="row.drive_from_hours"
                    value-format="HH:mm"
                />
                <span>to</span>
                <el-time-picker
                    :class="{'validate-error': !isFieldValid(idx, 'drive_to_hours')}"
                    :clearable="false"
                    :placeholder="$t('system.to')"
                    :size="size"
                    @change="updateTotalRowValue(row, 'drive')"
                    class="time-picker mx-1"
                    format="hh:mm A"
                    v-model="row.drive_to_hours"
                    value-format="HH:mm"
                />
              </div>
            </div>
            <div class="d-flex justify-content-around">
              <b>Labor</b>
              <div>
                <el-time-picker
                    :class="{'validate-error': !isFieldValid(idx, 'labor_from_hours')}"
                    :clearable="false"
                    :placeholder="$t('system.from')"
                    :size="size"
                    @change="updateTotalRowValue(row, 'labor')"
                    class="time-picker mx-1"
                    format="hh:mm A"
                    v-model="row.labor_from_hours"
                    value-format="HH:mm"
                />
                <span>to</span>
                <el-time-picker
                    :class="{'validate-error': !isFieldValid(idx, 'labor_to_hours')}"
                    :clearable="false"
                    :placeholder="$t('system.to')"
                    :size="size"
                    @change="updateTotalRowValue(row, 'labor')"
                    class="time-picker mx-1"
                    format="hh:mm A"
                    v-model="row.labor_to_hours"
                    value-format="HH:mm"
                />
              </div>
            </div>
          </div>
        </template>
        <template v-slot:item.hoursAndMinutes="{row}">
          <div class="d-flex mx-2 flex-column justify-content-around align-center table__cell">
            <span v-html="getTimeDiff(row.drive_from_hours, row.drive_to_hours)" />
            <span v-html="getTimeDiff(row.labor_from_hours, row.labor_to_hours)" />
          </div>
        </template>
        <template v-slot:item.rate="{row, idx}">
          <div class="d-flex mx-2 flex-column justify-content-around align-center table__cell">
            <el-input-number
                :class="{'validate-error': !isFieldValid(idx, 'drive_rate')}"
                :min="0"
                :size="size"
                @change="updateTotalRowValue(row, 'drive')"
                controls-position="right"
                type="number"
                v-model.number="row.drive_rate"
            />
            <el-input-number
                :class="{'validate-error': !isFieldValid(idx, 'labor_rate')}"
                :min="0"
                :size="size"
                @change="updateTotalRowValue(row, 'labor')"
                controls-position="right"
                type="number"
                v-model.number="row.labor_rate"
            />
          </div>
        </template>
        <template v-slot:item.isBillable="{row}">
          <div class="d-flex mx-1 flex-column justify-content-around align-center table__cell">
            <el-checkbox
                class="mr-0"
                v-model="row.drive_billable"
            />
            <el-checkbox v-model="row.labor_billable" />
          </div>
        </template>
        <template v-slot:item.total="{row}">
          <div class="d-flex mx-1 flex-column justify-content-around align-center table__cell">
            <b>${{ row.totalDrive.toFixed(2) }}</b>
            <b>${{ row.totalLabor.toFixed(2) }}</b>
          </div>
        </template>
        <template v-slot:item.options="{row}">
          <div class="d-flex justify-content-center align-content-center cell_remove">
            <i
                @click.stop="removeItem(row)"
                slot="reference"
            >
              <font-awesome-icon
                  icon="times"
              />
            </i>
          </div>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapActions, mapGetters} from 'vuex';
import DataTable from '@/components/Table/DataTable';
import {timeDiff, timeToDecimal} from '@/helpers/time';
import validationMixin from '@/components/WorkOrderComponents/Payments/validationMixin';
import notification from '@/notification/notify';
import swal from 'sweetalert2';

export default {
  name: 'DriveAndLaborTimes',
  components: {DataTable},
  mixins: [validationMixin],
  props: {
    workOrderId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    size: 'mini',
    tableHeaders: [
      {
        key: 'options',
        label: '',
        sort: false,
        width: '36px',
      },
      {
        key: 'personAndDate',
        label: 'system.person_and_date',
        sort: false,
        width: '250px',
      },
      {
        key: 'driveAndLaborTime',
        label: 'system.drive_and_labor_time',
        sort: false,
      },
      {
        key: 'hoursAndMinutes',
        label: 'system.hours_and_minutes',
        sort: false,
      },
      {
        key: 'rate',
        label: 'system.rate',
        sort: false,
      },
      {
        key: 'isBillable',
        label: 'system.billable',
        sort: false,
      },
      {
        key: 'total',
        label: 'system.total',
        sort: false,
      },
    ],
    techniciansAndContractorsList: [],
    loading: false,
  }),
  computed: {
    ...mapGetters('workOrderPayments', ['workOrderDriveAndLaborTimes']),
    data() {
      return this.workOrderDriveAndLaborTimes(this.workOrderId);
    },
  },
  created() {
    this.fetchTechniciansAndContractorsList();
  },
  methods: {
    ...mapActions('workOrderPayments', ['removeDriveAndLaborTime', 'addDriveAndLaborTime']),

    async removeItem(row) {
      const result = await swal.fire({
        target: this.$refs.root,
        title: this.$t('system.remove'),
        text: this.$t('system.if_you_sure'),
        type: 'info',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: this.$t('system.yes'),
        cancelButtonText: this.$t('system.no'),
      });
      if (result.value) {
        this.removeDriveAndLaborTime({driveAndLaborId: row.id, workOrderId: this.workOrderId});
      }
    },
    addItem() {
      this.addDriveAndLaborTime({workOrderId: this.workOrderId});
    },
    async fetchTechniciansAndContractorsList() {
      try {
        const {data} = await axios.get('workorders/expense/technicians_contractors');
        this.techniciansAndContractorsList = data;
      } catch (error) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_load'),
            'error');
      }
    },
    updateTotalRowValue(row, type) {
      if (type === 'drive') {
        row.drive_rate = (Math.round((row.drive_rate) * 100) / 100) || 0;
        const driveTimeDiff = timeDiff(row.drive_from_hours, row.drive_to_hours, 'HH:mm', 'HH:mm');
        row.totalDrive = Math.round(timeToDecimal(driveTimeDiff) * row.drive_rate * 100) / 100;
      } else if (type === 'labor') {
        row.labor_rate = (Math.round((row.labor_rate) * 100) / 100) || 0;
        const laborTimeDiff = timeDiff(row.labor_from_hours, row.labor_to_hours, 'HH:mm', 'HH:mm');
        row.totalLabor = Math.floor(timeToDecimal(laborTimeDiff) * row.labor_rate * 100) / 100;
      }
    },
    getTimeDiff(start, end) {
      if (start && end) {
        const [hours, minutes] = timeDiff(start, end, 'HH:mm', 'HH:mm').split(':');
        return `<b>${hours || '00'}</b>h <b>${minutes || '00'}</b>m`;
      }
      return `<b>00</b>h <b>00</b>m`;
    },
    async saveTableData() {
      try {
        this.loading = true;
        await axios.post(`workorders/expense/${this.workOrderId}/drive_labor`, {
          positions: this.data,
        });
        this.validationErrors = {};
        notification.notify(
            this.$t('notify.success'),
            this.$t('system.saved'),
            'success');
      } catch (error) {
        if (error.response?.status === 422) {
          this.setValidationData(error.response.data);
          notification.notify(
              this.$t('notify.error'),
              this.$t('system.check_fields'),
              'warning');
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style
    lang="scss"
    scoped
>
.table {
  &__cell {
    height: 100px;
  }
}
</style>

<style lang="scss">
.table {
  &__cell {
    .time-picker {
      width: 100px;

      .el-input__inner {
        padding-right: 0;
      }
    }
  }
}
</style>
