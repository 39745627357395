import Vue from 'vue';

export default {
  data: () => ({
    validationErrors: {},
  }),
  methods: {
    isFieldValid(index, fieldName) {
      if (this.validationErrors[index]) {
        return !this.validationErrors[index].some(item => item === fieldName);
      }
      return true;
    },
    setValidationData(data) {
      const keys = Object.keys(data);
      keys.forEach((key) => {
        const [someValue, idx, fieldName] = key.split('.');

        if (this.validationErrors[idx]) {
          Vue.set(this.validationErrors, idx, [...this.validationErrors[idx], fieldName]);
        } else {
          Vue.set(this.validationErrors, idx, [fieldName]);
        }
      });
    },
  },
};
